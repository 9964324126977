.heading {
  font-size: 1.25rem;
  color: var(--primary);
  font-weight: bold;
  margin-bottom: 12px;
}
.inner {
  width: 90%;
  margin: 32px auto;
  display: flex;
  flex-direction: column;
}

.notice {
  font-weight: bold;
  text-align: center;
  font-size: 1.25rem;
}

.opButton {
  appearance: none;
  color: #fff;
  background: var(--primary);
  font-size: 1rem;
  border: none;
  margin-right: 8px;
  border-radius: 2px;
  padding: 4px 8px;
  cursor: pointer;
  &:last-child {
    margin-right: 0;
  }
  &Danger {
    background: crimson;
  }
}

.text {
  margin-bottom: 8px;
}

.btn {
  margin-top: 32px;
  margin-right: 12px;
  &:last-child {
    margin-right: 0;
  }
}

.buttons {
  margin-top: 24px;
}
.backBtn {
  margin-right: 12px;
}
.deleteBtn {
  background: crimson;
}

.customerInfoWrapper {
  width: 100%;
}

@media (min-width: 800px) {
  .customerInfoWrapper {
    width: 100%;
  }
}
