.feed {
  background: var(--primary);
  color: #fff;
  padding: 0 5%;
  &__heading {
    font-size: 0.7rem;
    padding: 10px 0 9px 0;
    line-height: 1.6;
  }
  &__text {
    color: #fff;
    text-decoration: none;
  }
}

.header {
  &__left {
    margin: 10px 0;
  }
  &__title {
    font-size: 1.75rem;
    font-weight: bold;
    color: var(--primary);
    line-height: 1.25;
  }
  &__inner {
    padding: 0 5%;
  }
}
