.header {
  background: var(--primary);
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 21px 24px;
  &__title {
    font-size: 1.25rem;
    font-weight: bold;
  }
  &__displayName {
    margin-right: 12px;
  }
  &__right {
    align-items: center;
    display: flex;
  }
  &__link {
    text-decoration: none;
    color: #fff;
  }
}
.pointer {
  cursor: pointer;
}