.content {
  padding: 12px 5%;
}

.heading {
  font-size: 1.75rem;
  font-weight: bold;
  color: var(--primary);
  line-height: 1.25;
  margin: 12px 0;
}


.input {
  display: block;
  margin: 4px 0 12px 0;
}

.button {
  margin-bottom: 24px;
}