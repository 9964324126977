.button {
  background: var(--primary);
  color: #fff;
  font-size: 1rem;
  padding: 8px 24px;
  border-radius: 4px;
  appearance: none;
  cursor: pointer;
  border: none;
  &__disabled {
    background: #555;
  }
}
