.content {
    padding: 0 5%;
}

.heading {
    font-size: 1.75rem;
    font-weight: bold;
    color: var(--primary);
    line-height: 1.25;
    margin: 12px 0;
}