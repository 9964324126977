.wrapper {
  width: 100%;
  margin: 0 auto;
}
.heading {
  font-size: 1.25rem;
  color: var(--primary);
  font-weight: bold;
  margin: 12px 0;
}
.inner {
  width: 90%;
  margin: 32px auto;
}
.label {
  display: inline-block;
  background: var(--primary);
  color: #fff;
  padding: 4px;
  border-radius: 4px;
  margin-bottom: 4px;
}

.text {
  margin-bottom: 8px;
}

.btn {
  margin-top: 32px;
  margin-right: 12px;
  &:last-child {
    margin-right: 0;
  }
}