.tableHead {
  display: block;
  text-align: center;
  margin-bottom: 8px;
}
.tableDay {
  margin-right: 8px;
}
.tableCell {
  text-align: center;
}
