.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  padding: 12px;
}
.inner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &__title {
    font-size: 1.75rem;
    font-weight: bold;
    color: var(--primary);
    margin-bottom: 24px;
    text-align: center;
  }
  &__input {
    max-width: 280px;
    width: 90%;
    margin-bottom: 18px;
  }
}
.error {
  background: crimson;
  color: #fff;
  margin-top: 24px;
  padding: 12px;
  border-radius: 2px;
  box-shadow: 0 0 2px rgba(153, 0, 0, 0.25);
  text-align: center;
}