.cursors {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 12px;
  &__cursor {
    margin-right: 32px;
    &:last-child {
      margin-right: 0;
    }
  }
}
.calendarDayWrapper {
  display: flex;
  justify-content: center;
}