.title {
  background: var(--primary);
  color: #fff;
  text-align: center;
  padding: 14px 0;
  font-weight: bold;
}
.body {
  padding: 14px;
}
.buttons {
  display: flex;
  justify-content: space-around;
  margin: 12px 24px;
}
.danger {
  background: crimson;
}
