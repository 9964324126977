.calendarCell {
  border: 2px solid #69774b;
  appearance: none;
  font-size: 1rem;
  padding: 6px 8px;
  cursor: pointer;
  margin-bottom: 12px;
  border-radius: 2px;
  background: #fff;
  width: 64px;
  &__disabled {
    color: #fff;
    background: #555;
  }
  &__selected {
    background: var(--primary);
    color: #fff;
  }
}
