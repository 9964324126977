.wrapper {
  width: 100%;
  margin: 0 auto;
}
.heading {
  font-size: 1.25rem;
  color: var(--primary);
  font-weight: bold;
  margin: 12px 0;
}
.inner {
  width: 90%;
  margin: 32px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__centering {
    text-align: center; 
  }
}
.textArea {
  width: 320px;
  height: 140px;
  margin-bottom: 24px;
}
.confirmButton {
  display: block;
  margin: 0 auto;
}
